/* latin */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Inter Regular'), local('Inter-Regular'),
		url('/fonts/inter-regular-optimized.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url('/fonts/inter-regular-optimized.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* latin */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: bold;
	font-display: swap;
	src: local('Inter Heavy'), local('Inter-Heavy'),
		url('/fonts/inter-bold-optimized.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url('/fonts/inter-bold-optimized.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html,
body {
	min-height: 100vh;
	padding: 0;
	margin: 0;
	font-family: var(--copy-font);
}

*, *::before, *::after {
	box-sizing: border-box;
}

html {
	-webkit-font-smoothing: subpixel-antialiased;
}

body {
	font-family: var(--copy-font);
	color: #3c444d;
}

/* https://www.figma.com/design/hoZH77E8OUNMnk4sJL1xIT/CR---Update?node-id=114-7207&node-type=frame&t=Rog3aZ4SeHogEfzD-0 */
:root {
	--title-font: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
	--copy-font: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
	--font-base: 16px;
	--font-base-height: 24px;

	--success: #43A047;
	--warning: #ffad0d;
	--error: #e35141;

	--box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.3);
	--box-shadow-100: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
	0px 1px 2px rgba(0, 0, 0, 0.3);

	--gray-100: #fafafa;
	--gray-200: #eeeeee;
	--gray-300: #cccccc;
	--gray-400: #6a6a6a;
	--gray-500: #1a1a1a;

	--primary: #2196F3;
	--primary-100: #E9F4FE;
	--primary-200: #90CAF9;
	--primary-300: #2196F3;
	--primary-400: #145A92;

	--interactive: #2196F3;
	--interactive-100: #E9F4FE;
	--interactive-200: #90CAF9;
	--interactive-300: #2196F3;
	--interactive-400: #145A92;

	--gradient-100: linear-gradient(183.11deg, rgba(248, 238, 229, 0.4) 2.57%, rgba(229, 235, 245, 0.4) 97.42%);
	--gradient-200: linear-gradient(90deg, #EFF6F7 0.02%, #F8EEE5 99.96%);
}

a {
	color: inherit;
	text-decoration: none;
	background-color: transparent;
}

a:active, a:hover {
	outline: 0;
}

b, strong {
	font-weight: bold;
}

div {
	font-size: 14px;
}

h2 {
	margin-bottom: 30px;
}

h3 {
	position: relative;
	font-size: 14px;
	color: #2f343c;
	font-weight: bold;
	margin-bottom: 15px;
}

h4 {
	font-size: 11px;
	opacity: 0.5;
	font-weight: 900;
}

h5 {
	font-weight: normal;
	font-size: 20px;
	margin: 0 0 15px;
	color: #2196f3;
}

hr {
	background-color: #F0F0F0;
	border-style: none;
	margin-bottom: 15px;
	height: 0px;
	box-sizing: content-box;
}

button, button:active {
	outline: none;
	border: none;
	cursor: pointer;
}

input[type="radio"] {
	border: none;
	box-shadow: none;
}

pre {
	margin: 0 0 10px;
	font-size: 11px;
	overflow-x: scroll;
	white-space: pre-wrap;
	max-width: 600px;
	background-color: #fff;
	border: 1px solid #a0a0a0;
	padding: 10px;
}

sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

img {
	border: 0;
}

svg:not(:root) {
	overflow: hidden;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

button, input, optgroup, select, textarea {
	color: inherit;
	font: inherit;
}

::placeholder {
	color: #7f8fa4;
	opacity: 0.5;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	font-weight: bold;
}

h1,
.h1,
.text-4xl {
	font-size: 36px;
	line-height: 44px;
}

h2,
.h2,
.text-3xl {
	font-size: 30px;
	line-height: 40px;
}

h3,
.h3,
.text-2xl {
	font-size: 24px;
	line-height: 32px;
}

h4,
.h4,
.text-xl {
	font-size: 20px;
	line-height: 32px;
}

.text-lg {
	font-size: 18px;
	line-height: 28px;
}

.text-base {
	font-size: 16px;
	line-height: 24px;
}

.text-sm {
	font-size: 14px;
	line-height: 20px;
}

.text-xs {
	font-size: 12px;
	line-height: 16px;
}

.text-xxs {
	font-size: 10px;
	line-height: 16px;
}
